<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
        v-if="hasLoaded"
      )
        v-row.mb-6(
          align="center"
          justify="space-between"
        )
          v-col(
            cols="12"
            md="auto"
          )
            v-layout(
              align-center
            )
              h1.text-h4 Invoice \#{{ invoice.invoiceNumber }}
              v-tooltip(
                top
              )
                template(#activator="{ on, attrs}")
                  v-btn.px-0.ml-5(
                    v-on="on"
                    min-width="40px"
                    :to="{ name: 'print-invoice', params: { invoiceId: invoice.id }}"
                    target="_blank"
                    color="primary"
                  )
                    v-icon mdi-printer
                span Print Invoice
          v-col(
            cols="12"
            md="auto"
          )
            v-tooltip(
              top
            )
              template(#activator="{ on, attrs}")
                v-btn.px-0.mr-2(
                  v-on="on"
                  min-width="40px"
                  @click="deleteInvoice"
                  color="error"
                )
                  v-icon mdi-delete
              span Delete Invoice
            v-btn(
              :disabled="invoice.ccc1Exported"
              :to="{ name: 'edit-invoice', params: { invoiceId: invoice.id }}"
              color="primary"
            )
              v-icon(
                left
              ) mdi-pencil
              | Edit Invoice

        v-card(
          outlined
          v-if="hasLoaded"
        )
          v-card-text
            v-row
              v-col(
                cols="12"
                md="6"
                lg="4"
              )
                v-text-field(
                  label="Date"
                  filled
                  :value="invoice.formattedDate"
                  readonly
                )

              v-col(
                cols="12"
                md="6"
                lg="4"
              )
                v-text-field(
                  label="RO"
                  filled
                  :value="invoice.roNumber || 'None'"
                  readonly
                )

              v-col(
                cols="12"
                md="6"
                lg="4"
              )
                v-text-field(
                  label="Tech"
                  filled
                  :value="invoice.tech ? invoice.tech.name : 'None'"
                  readonly
                )

          v-data-table.clickable(
            :headers="headers"
            no-data-text="No Products Found"
            disable-pagination
            :items="rows"
            @click:row=""
            hide-default-footer
            :items-per-page="-1"
          )
            template(#item.listPrice="{ item: product }")
              span ${{ Number(product.listPrice).toFixed(2) | round(2) }}
            template(#item.costPrice="{ item: product }")
              span ${{ Number(product.costPrice).toFixed(2) | round(2) }}
            template(#item.listSubtotal="{ item: product }")
              span ${{ Number(product.listSubtotal).toFixed(2) | round(2) }}
            template(#item.costSubtotal="{ item: product }")
              span ${{ Number(product.costSubtotal).toFixed(2) | round(2) }}

        v-layout.mt-5(
          justify-center
        )
          v-btn(
            v-if="invoice.shop.exportCCC1 && invoice.shop.ccc1LicenseNo && invoice.roNumber"
            color="primary"
            @click="exportToCCC1"
            :disabled="invoice.ccc1Exported"
          ) {{ invoice.ccc1Exported ? "Exported to CCC1" : "Export to CCC1" }}
            v-icon(right) {{ invoice.ccc1Exported ? "mdi-lock" : "mdi-export" }}
</template>

<script>
import { useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'Invoice',
  props: {
    invoiceId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Invoice } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return { query: { $eager: 'tech', $mergeEager: 'shop' } }
    })

    // Get the patient record
    const { item: invoice, hasLoaded } = useGet({
      params,
      model: Invoice,
      id: props.invoiceId
    })
    const { InvoiceRow } = context.root.$FeathersVuex.api

    const rowsParams = computed(() => {
      const query = {
        query: {
          invoice_id: props.invoiceId,
          $limit: 99
        }
      }

      return query
    })

    const { items: rows, isPending: areRowsPending } = useFind({
      model: InvoiceRow,
      params: rowsParams
    })

    return {
      rows,
      areRowsPending,
      invoice,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async deleteInvoice () {
      try {
        const message = 'Are you sure you want to delete this invoice?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        const currentInvoice = this.invoice

        if (isYes) {
          await this.invoice.remove()
          this.$snackSuccess('Invoice Deleted')
          this.$router.push({ name: 'shop', params: { shopId: currentInvoice.shop_id } })
        }
      } catch (error) {
        this.$logError(error)
      }
    },
    async exportToCCC1 () {
      try {
        const message = 'Are you sure you want to export this invoice? Once it\'s exported, it can no longer be edited.'
        const isYes = await this.$confirm(message)

        // const currentInvoice = this.invoice

        if (isYes) {
          // const response = await this.invoice.patch({ data: { ...this.invoice, ccc1Exported: true } })
          this.$snackSuccess('Invoice Exported')
          // TODO: forced to reload page due to exportToCCC1 button not being reactive
          location.reload()
        }
      } catch (error) {
        this.$logError(error)
        this.$snackError(error.message)
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'name'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Cost Price',
        value: 'costPrice'
      },
      {
        text: 'List Price',
        value: 'listPrice'
      },
      {
        text: 'Cost Subtotal',
        value: 'costSubtotal'
      },
      {
        text: 'List Subtotal',
        value: 'listSubtotal'
      }
    ]
  })
}
</script>
